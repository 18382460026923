<template>
	<div>
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="配置项名称">
				<Input placeholder="请输入配置项名称" v-model="searchParams.nameLike" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="标签名">
				<Input placeholder="请输入标签名" v-model="searchParams.tagLike" />
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		>
			<template slot-scope="{ row }" slot="code">
				<div style="overflow: hidden">
					<!-- type 1 签名 ，0 其他控件-->
					<span
						>{{<span>{{ row.type === 1 ? `@${row.code}` : row.code }}</span
						>}}</span
					>
					<Button
						size="small"
						style="float: right; margin-right: 20px"
						id="btn_Share"
						@click="copy(row)"
						>复制</Button
					>
				</div>
			</template>
		</CtmsDataGrid>
	</div>
</template>

<script>
import { mapState } from "vuex"
import api from "@/api/setting/form"
import ClipboardJS from "clipboard"

const { getFormFilePlaceholder } = api

export default {
	name: "ConfigCode",
	data() {
		return {
			loading: false,
			columns: [
				{
					title: "配置项名称",
					key: "name",
					minWidth: 300,
					align: "center"
				},
				{
					title: "唯一编码",
					minWidth: 200,
					align: "center",
					slot: "code"
				},
				{
					title: "标签名",
					minWidth: 150,
					align: "center",
					key: "tag"
				}
			],
			listData: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			originData: [],
			searchParams: {
				nameLike: "",
				tagLike: ""
			},
			oldSearchParams: {
				nameLike: "",
				tagLike: ""
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 150
		})
		// ...mapState('permission', ['systemActionPermissions']),
	},
	methods: {
		async initList() {
			this.loading = true
			const res = await getFormFilePlaceholder({
				...this.oldSearchParams,
				pageNum: this.page.current,
				pageSize: this.page.pageSize
			})
			if (res && res.data) {
				if (res.data.list) {
					this.listData = res.data.list || []
					this.originData = [...res.data.list]
				}
				this.page.total = res.data.total
			}
			this.loading = false
		},
		// 复制编码
		copy(row) {
			const clipboard = new ClipboardJS("#btn_Share", {
				text() {
					return `{{${row.type === 1 ? `@${row.code}` : row.code}}}`
				}
			})

			clipboard.on("success", e => {
				this.$Message.success("复制成功")
				e.clearSelection()
				clipboard.destroy()
			})

			clipboard.on("error", e => {
				this.$Message.error("复制失败")
				clipboard.destroy()
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.page.current = 1
			this.searchParams = {
				nameLike: "",
				tagLike: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.page.current = 1
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.initList()
			})
		}
	},
	created() {
		this.initList()
	}
}
</script>

<style scoped></style>
